import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['entries', 'pagination']

  connect() {
    //console.log("hello infinite_scroll")
    this.loadingMore = false
  }

  scroll() {
    if (this.loadingMore) return

    let next_page = this.paginationTarget.querySelector("a[aria-label='Next']")
    if (next_page == null) return

    console.log("we are going to load more")

    let url = next_page.href

    // Get the container element where the scrolling is happening
    const container = this.element
    // Or use a dedicated target if you prefer:
    // const container = this.wrapperTarget

    // Check how close we are to the bottom of this container
    const scrollPosition = container.scrollTop + container.clientHeight
    const scrollHeight = container.scrollHeight

    // If we’re within, say, 100px of the bottom, load more
    if (scrollPosition >= scrollHeight - 100) {
      this.loadMore(url)
    }
  }


  loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      beforeSend: () => {
        this.loadingMore = true
        return true
      },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.currentPage = data.current_page
        this.loadingMore = false
        //console.log(this.currentPage)
      }
    })
  }

}